import React, { PureComponent } from 'react';
import ProductRelease from '../../lib/models/ProductRelease';
import { Link } from 'react-router-dom';
import Octicon, { ArrowLeft } from '@githubprimer/octicons-react';
import ProductReleaseDetailV01 from './ProductReleaseV01';
import ProductReleaseDetailV02 from './ProductReleaseV02';

const ProductReleaseDetailComponents = {
  '0.1': ProductReleaseDetailV01,
  '0.2': ProductReleaseDetailV02
};

const ProductReleaseDetailError = ({ version }) => {
  return <div className="alert alert-danger">productConfig version {version} not supported</div>;
};

class ProductReleaseDetail extends PureComponent {
  state = {
    release: false,
    error: '',
    ProductReleaseDetail: undefined
  };

  render() {
    const { ProductReleaseDetail, release, error } = this.state;
    const { match } = this.props;
    const { params } = match;
    return (
      <>
        <Link to="/product-releases" className="btn btn-info btn-sm mb-3">
          <Octicon icon={ArrowLeft} size="small" /> back
        </Link>
        <h2>{params.id}</h2>
        {!!error && <div className="alert alert-danger">{error}</div>}
        {release && <ProductReleaseDetail release={release} version={release.productConfig} />}
      </>
    );
  }

  componentDidMount() {
    const { match } = this.props;
    const { params } = match;
    ProductRelease.get(params.id)
      .then(release => {
        let ProductReleaseDetail;
        if (!release.productConfig) {
          release.productConfig = '0.1.0';
        }

        const [major, minor] = release.productConfig.split('.');
        const version = major === '0' ? `${major}.${minor}` : major;

        if (!ProductReleaseDetailComponents[version]) {
          ProductReleaseDetail = ProductReleaseDetailError;
        } else {
          ProductReleaseDetail = ProductReleaseDetailComponents[version];
        }

        this.setState({ release, ProductReleaseDetail });
      })
      .catch(e => this.setState({ error: e.message }));
  }
}

export default ProductReleaseDetail;
