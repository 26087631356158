import React, { PureComponent } from 'react';

class Loading extends PureComponent {
  render() {
    return (
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-4 offset-md-4 col-6 offset-3">
            <div className="progress" style={{ marginTop: '50px' }}>
              <div
                className="progress-bar progress-bar-striped active progress-bar-animated"
                role="progressbar"
                aria-valuenow="100"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: '100%' }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Loading;
