import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { setTitle } from '../../lib/utils/windowutils';

class AdminMain extends PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    setTitle('Admin');
  }

  render() {
    const { match } = this.props;
    return (
      <div className="mt-3">
        <div className="row mb-3">
          <div className="col-md-6">
            <div className="card border-info">
              <div className="card-header bg-info text-white">
                <strong>Administration</strong>
              </div>
              <div className="card-body">
                <div className="list-group">
                  <Link to={`${match.path}/domains`} className="list-group-item list-group-item-action">
                    Domains
                  </Link>
                  <Link to={`${match.path}/accounts`} className="list-group-item list-group-item-action">
                    Accounts
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AdminMain;
