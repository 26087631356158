import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Loading from '../Common/Loading';
import DescriptionEdit from '../Common/DescriptionEdit';
import { handleOnKeyDown } from '../../lib/utils/commonutils';
import { setTitle } from '../../lib/utils/windowutils';
import Domains from '../../lib/models/Domains';
import { ltToast } from '../Common/LTToast';
import { Link } from 'react-router-dom';
import Octicon, { Trashcan, Plus, Dash, CloudUpload } from '@githubprimer/octicons-react';

class DomainView extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  state = {
    domain: false,
    customer_id: false,
    customers: { rows: [] },
    editField: '',
    editValue: '',
    editing: false,
    add_email: false,
    email: ''
  };

  refEmail = React.createRef();

  refInput = React.createRef();

  render() {
    const { domain, add_email, editing, editField, editValue, email } = this.state;
    if (!domain) {
      return <Loading />;
    }

    return (
      <div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/admin/">Admin</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/admin/domains">Domains</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {domain.domain}
            </li>
          </ol>
        </nav>
        <h5 className="mb-3">Edit Domain</h5>
        <div className="row">
          <div className="mb-3 col-12 col-md-6 col-lg-4">
            <div className="card border-info">
              <div className="card-header bg-info text-white">
                <strong>Detail</strong>
              </div>
              <div className="card-body">
                <dl className="row">
                  <dt className="col-12 col-md-4 col-lg-3">Name</dt>
                  <dd
                    className="col-12 col-md-8 col-lg-9"
                    onClick={e => this.setState({ editField: 'domain', editValue: domain.domain, editing: true })}
                  >
                    {(!editing || editField !== 'domain') && <DescriptionEdit>{domain.domain}</DescriptionEdit>}
                    {editing && editField === 'domain' && (
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        value={editValue}
                        ref={this.refInput}
                        onBlur={this.saveEdit}
                        onKeyDown={e => handleOnKeyDown(e, this.saveEdit)}
                        onChange={e => this.setState({ editValue: e.target.value })}
                      />
                    )}
                  </dd>
                  <dt className="col-12 col-md-4 col-lg-3">Access Type</dt>
                  <dd
                    className="col-12 col-md-8 col-lg-9"
                    onClick={e =>
                      this.setState({ editField: 'access_type', editValue: domain.access_type, editing: true })
                    }
                  >
                    {(!editing || editField !== 'access_type') && (
                      <DescriptionEdit>{domain.access_type}</DescriptionEdit>
                    )}
                    {editing && editField === 'access_type' && (
                      <select
                        className="form-control form-control-sm"
                        onChange={e => this.setState({ editValue: e.target.value })}
                        onBlur={this.saveEdit}
                        value={editValue}
                      >
                        <option value="full">Full</option>
                        <option value="whitelist">Whitelist</option>
                      </select>
                    )}
                  </dd>
                  <dt className="col-12 col-md-4 col-lg-3">Status</dt>
                  <dd
                    className="col-12 col-md-8 col-lg-9"
                    onClick={e => this.setState({ editField: 'status', editValue: domain.status, editing: true })}
                  >
                    {(!editing || editField !== 'status') && (
                      <DescriptionEdit>{domain.status ? 'Enabled' : 'Disabled'}</DescriptionEdit>
                    )}
                    {editing && editField === 'status' && (
                      <select
                        className="form-control form-control-sm"
                        onChange={e => this.setState({ editValue: e.target.value })}
                        onBlur={this.saveEdit}
                        value={editValue}
                      >
                        <option value={0}>Disable</option>
                        <option value={1}>Enable</option>
                      </select>
                    )}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          {domain.access_type === 'whitelist' && (
            <div className="mb-3 col-12 col-md-6 col-lg-4">
              <div className="card border-info">
                <div className="card-header bg-info text-white">
                  <strong>WHITELIST</strong>
                </div>
                <div className="card-body">
                  <div className="list-group mb-3">
                    {domain.whitelist.map((email, i) => {
                      const created_at = new Date(email.created_at);
                      return (
                        <div key={i} className="list-group-item">
                          <button
                            type="button"
                            className="btn btn-link float-right"
                            onClick={e => this.handleDeleteEmail(email)}
                          >
                            <Octicon icon={Trashcan} size="small" />
                          </button>
                          <div>{email.email}</div>
                          <small>
                            Added by {email.created_by}, {created_at.toLocaleDateString('it-IT')}{' '}
                            {created_at.toLocaleTimeString('it-IT')}
                          </small>
                        </div>
                      );
                    })}
                  </div>
                  {add_email && (
                    <form onSubmit={this.handleSubmitEmail}>
                      <div className="form-group">
                        <div className="input-group">
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            placeholder="Enter Email"
                            autoComplete="off"
                            value={email}
                            ref={this.refEmail}
                            onChange={this.handleChange}
                          />
                          <div className="input-group-append">
                            <button type="submit" className="input-group-text">
                              <Octicon icon={CloudUpload} size="small" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                  <button className="btn btn-info" onClick={e => this.setState({ add_email: !this.state.add_email })}>
                    <Octicon icon={add_email ? Dash : Plus} />
                  </button>
                </div>
              </div>
            </div>
          )}
          <div className="mb-3 col-12 col-md-6 col-lg-4">
            <div className="card border-danger">
              <div className="card-header bg-danger text-white">
                <strong>DANGER ZONE</strong>
              </div>
              <div className="card-body">
                <button className="btn btn-danger" onClick={this.handleDelete}>
                  <Octicon icon={Trashcan} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.loadDomain();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.domain && this.state.domain.id && !prevState.domain.id) {
      setTitle(this.state.domain.domain);
    }
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.loadDomain();
    }
    if (!prevState.editing && this.state.editing && this.refInput.current) {
      this.refInput.current.focus();
    }
    if (!prevState.add_email && this.state.add_email) {
      this.refEmail.current.focus();
    }
  }

  handleDeleteEmail = email => {
    const { domain } = this.state;
    Domains.deleteWhitelist(domain.id, email.id)
      .then(domain => {
        this.setState({ domain });
      })
      .catch(e => {
        ltToast('Unable to add email: ' + e.message, 3000, true);
      });
  };

  handleSubmitEmail = e => {
    e.preventDefault();
    const { email, domain } = this.state;
    if (!email) {
      return;
    }
    if (email.split('@')[1] !== domain.domain) {
      ltToast('Email domain does not match!', 3000, true);
      this.setState({ email: '' });
      return;
    }
    Domains.addWhitelist(domain.id, email)
      .then(domain => {
        this.setState({ add_email: false, domain, email: '' });
      })
      .catch(e => {
        ltToast('Unable to add email: ' + e.message, 3000, true);
      });
  };

  handleChange = e => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value
    });
    this.setState({ editField: e.target.name, editValue: e.target.value });
  };

  handleDelete = e => {
    e.preventDefault();
    const ok = window.confirm('Are you sure?');
    if (ok) {
      const { domain } = this.state;
      Domains.delete(domain.id)
        .then(() => {
          this.props.history.push('/admin/domains');
        })
        .catch(e => {
          ltToast('Unable to delete entity: ' + e.message, 5000, true);
        });
    }
  };

  saveEdit = () => {
    const { editField, editValue, domain } = this.state;
    this.setState({ editField: '', editValue: '', editing: false });
    this.refInput = React.createRef();
    if (domain[editField] !== editValue) {
      Domains.update(domain.id, editField, editValue)
        .then(domain => {
          this.setState({ domain });
        })
        .catch(e => {
          ltToast('Unable to save: ' + e.message, 5000, true);
        });
    }
  };

  loadDomain = () => {
    const { match } = this.props;
    const { params } = match;
    Domains.get(params.id)
      .then(domain => {
        this.setState({
          domain
        });
      })
      .catch(e => console.error('err', e.message));
  };
}

export default DomainView;
