import { getData } from '../utils/httputils';

const apiPath = '/api/v1/admin/roles';

let roles;

export const loadRoles = async () => {
  if (!roles) {
    roles = await getData(apiPath);
  }
  return roles;
};

export const getRoles = account_role => {
  const ret = [];
  if (!roles) {
    throw new Error('Roles not loaded');
  }
  roles.forEach(role => {
    if ((account_role & role.id) > 0) {
      ret.push(role);
    }
  });
  return ret;
};

export const getRolesAsString = account_role => {
  const roles = getRoles(account_role);
  const ret = roles.map(role => role.name).join(', ');
  return ret;
};
