import React from 'react';
import { Link } from 'react-router-dom';
import Octicon, { FileSubmodule, CloudDownload, Clippy } from '@githubprimer/octicons-react';
import { copyToClipboard } from '../../lib/utils/commonutils';
import { ltToast } from '../Common/LTToast';

const ProductReleaseDetailV02 = ({ release }) => {
  const getDownloadPath = function getDownloadPath(component, includeFile) {
    const [major, minor] = component.version.split('.');
    let path = `/releases/${component.releasesPath}`;
    if (!component.releasesPathSkipVersion) {
      path += `/${major}.${minor}`;
    }
    if (includeFile) {
      if (component.filename) {
        const fileName = component.filename
          .replace('{{ code }}', component.code)
          .replace('{{ version }}', component.version);
        path += `/${fileName}`;
      }
    }
    return path;
  };

  const copyDownloadAll = function(e) {
    e.stopPropagation();
    e.preventDefault();
    const lines = [];
    const command = 'curl -O --fail -H "Authorization: bearer $SL_RELEASES_TOKEN" ';
    Object.keys(release.smartlisComponents).forEach(componentKey => {
      const component = release.smartlisComponents[componentKey];
      if (!component.releasesPath) return;
      lines.push(`# ${component.name} ${component.note}`);
      const l = `${command} ${window.location.protocol}//${window.location.host}${getDownloadPath(component, true)}`;
      lines.push(l);
    });
    const text = lines.join('\n');
    copyToClipboard(text, () => {
      ltToast('Script copied to clipboard');
    });
  };

  return (
    <div className="row">
      <div className="col-xl-6 col-lg-6 col-md-8 col-12">
        <table className="table table-striped table-sm">
          <thead>
            <tr>
              <th>Component</th>
              <th>Version</th>
              <th>
                Actions{' '}
                <button
                  title="Copy Download All Script to Clipboard"
                  className="btn btn-sm btn-link"
                  onClick={copyDownloadAll}
                >
                  <Octicon icon={Clippy} />
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(release.smartlisComponents).map(componentKey => {
              const component = release.smartlisComponents[componentKey];
              return (
                <tr key={componentKey}>
                  <td>
                    {component.name}
                    <br />
                    <small>{component.note}</small>
                  </td>
                  <td>{component.version}</td>
                  <td>
                    {component.releasesPath && component.filename && (
                      <>
                        <button
                          title="Copy to Clipboard"
                          className="btn btn-sm btn-link"
                          onClick={e => {
                            e.stopPropagation();
                            e.preventDefault();
                            copyToClipboard(
                              `${window.location.protocol}//${window.location.host}${getDownloadPath(component, true)}`,
                              () => {
                                ltToast('Link copied to clipboard');
                              }
                            );
                          }}
                        >
                          <Octicon icon={Clippy} />
                        </button>
                        <Link className="" to={`/navigator/${getDownloadPath(component)}`}>
                          <Octicon icon={FileSubmodule} />
                        </Link>
                        <a className="ml-2" href={getDownloadPath(component, true)}>
                          <Octicon icon={CloudDownload} />
                        </a>
                      </>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProductReleaseDetailV02;
