import { deleteData, getData, postData, putData } from '../utils/httputils';

class Domains {
  static apiPath = '/api/v1/admin/domains';

  static create(data) {
    return postData(Domains.apiPath, data);
  }

  static find(params) {
    const { limit, offset, order_by, sort, search } = params;
    const searchQuery = search ? '&search=' + search : '';
    return getData(
      `${Domains.apiPath}?limit=${limit || 10}&offset=${offset || 0}&order_by=${order_by || 'id'}&sort=${sort ||
        'desc'}${searchQuery}`
    );
  }

  static get(id) {
    return getData(`${Domains.apiPath}/${id}`);
  }

  static update(id, field, value) {
    return putData(`${Domains.apiPath}/${id}`, { field, value });
  }

  static delete = id => {
    return deleteData(`${Domains.apiPath}/${id}`);
  };

  static addWhitelist = (id, email) => {
    return postData(`${Domains.apiPath}/${id}/whitelist`, { email });
  };

  static deleteWhitelist = (id, email_id) => {
    return deleteData(`${Domains.apiPath}/${id}/whitelist/${email_id}`);
  };
}

export default Domains;
