import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import TokenMain from './TokenMain';

class TokenRoot extends PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired
  };

  render() {
    const { match } = this.props;
    return (
      <div className="tokens-wrapper mt-3">
        <Switch>
          <Route exact path={match.path} component={TokenMain} />
        </Switch>
      </div>
    );
  }
}

export default TokenRoot;
