import { getData } from '../utils/httputils';

class ProductRelease {
  static apiPath = '/api/v1/product/releases';

  static list() {
    return getData(`${ProductRelease.apiPath}`);
  }

  static get = id => {
    return getData(`${ProductRelease.apiPath}/${id}`);
  };
}

export default ProductRelease;
