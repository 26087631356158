import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import GoogleLogin from '../Common/GoogleLogin';
import { doLogin } from '../../lib/models/user';
import settings from '../../lib/settings';

class LoginComp extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    settings: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired
  };

  render() {
    const { user } = this.props;
    if (user && user.data && user.data.email) {
      return (
        <div>
          <p>Logged in</p>
          <Link className="btn btn-info" to="/">
            Continue
          </Link>
        </div>
      );
    }
    return (
      <div className="row mt-5">
        <div className="col-12 col-md-6 offset-md-3">
          <div className="jumbotron">
            <h1 className="text-info">{settings.appDescription}</h1>
            <p>
              {this.props.user.loginFailReason ? <p>{this.props.user.loginFailReason}</p> : undefined}
              <GoogleLogin
                onSuccess={e => this.onSuccess(e)}
                onFailure={e => this.onFailure(e)}
                clientId={this.props.settings.data.auth.google.client_id}
              />
            </p>
          </div>
        </div>
      </div>
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.user.data) {
      const redirectURL = localStorage.getItem('redirectURL');
      localStorage.removeItem('redirectURL');
      this.props.history.push(redirectURL || '/');
    }
  }

  onSuccess(user) {
    this.props.dispatch(doLogin(user.tokenId));
  }

  onFailure(what) {
    console.log(what);
    alert('Failed to login..' + what);
  }
}

function mapStateToProps(state) {
  const { user, settings } = state;

  return {
    user,
    settings
  };
}

const Login = withRouter(connect(mapStateToProps)(LoginComp));
export default Login;
