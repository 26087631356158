import React from 'react';
import { Link } from 'react-router-dom';

const ProductReleaseDetailV01 = ({ release }) => {
  return (
    <div className="row">
      <div className="col-xl-6 col-lg-6 col-md-8 col-12">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Component</th>
              <th>Version</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
            {release['smartlis-components'].map((component, i) => {
              const [major, minor] = component.version.split('.');
              return (
                <tr key={component.name + i}>
                  <td>
                    <Link to={`/navigator/releases${component.repo}/${major}.${minor}`}>{component.name}</Link>
                  </td>
                  <td>{component.version}</td>
                  <td>{component.note}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProductReleaseDetailV01;
