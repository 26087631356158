import { deleteData, getData, postData, putData } from '../utils/httputils';

class Accounts {
  static apiPath = '/api/v1/admin/accounts';

  static create(data) {
    return postData(Accounts.apiPath, data);
  }

  static find(params) {
    const { limit, offset, order_by, sort, search } = params;
    const searchQuery = search ? '&search=' + search : '';
    return getData(
      `${Accounts.apiPath}?limit=${limit || 10}&offset=${offset || 0}&order_by=${order_by || 'id'}&sort=${sort ||
        'desc'}${searchQuery}`
    );
  }

  static get(id) {
    return getData(`${Accounts.apiPath}/${id}`);
  }

  static update(id, field, value) {
    return putData(`${Accounts.apiPath}/${id}`, { field, value });
  }

  static delete = id => {
    return deleteData(`${Accounts.apiPath}/${id}`);
  };

  static createToken = id => {
    return postData(`${Accounts.apiPath}/${id}/tokens`);
  };

  static deleteToken = (id, token) => {
    return deleteData(`${Accounts.apiPath}/${id}/tokens/${token}`);
  };
}

export default Accounts;
