import { getData } from '../../utils/httputils';

class Settings {
  static apiPath = '/api/v1';
  static getConfig() {
    return getData(Settings.apiPath + '/config');
  }
}

export default Settings;
