import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import checkUser from '../UserComponent';
import { setTitle } from '../../lib/utils/windowutils';
import Octicon, { Clippy, FileDirectory, File, Clock, Trashcan } from '@githubprimer/octicons-react';
import { copyToClipboard, getReadableFileSizeString } from '../../lib/utils/commonutils';
import { ltToast } from '../Common/LTToast';
import { deleteData } from '../../lib/utils/httputils';

const rootPath = '/releases';

const getParentDir = function(path) {
  const _path = path.split('/');
  _path.pop();
  return _path.join('/');
};

class Navigator extends PureComponent {
  static propTypes = {
    user: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    setTitle('Navigator');
  }

  state = {
    mtimesort: localStorage.getItem('navsortmtime') === '1',
    path: rootPath,
    data: {
      showUp: false,
      files: [],
      dirs: []
    }
  };

  render() {
    const { user } = this.props;
    if (!user.data.sl_reader) {
      return <div>Unauthorized</div>;
    }
    const { data, path, mtimesort } = this.state;
    const parentDir = data.showUp ? getParentDir(path) : false;

    let relPath = '';
    return (
      <div className="mt-3">
        <div className="row">
          <div className="col">
            <div className="d-flex">
              <div className="flex-grow-1">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item" />
                    {path.split('/').map((p, i) => {
                      if (!p) {
                        return null;
                      }
                      relPath += '/' + p;
                      const rp = relPath + '';
                      return (
                        <li className="breadcrumb-item" key={i}>
                          <button title={rp} className="btn btn-link m-0 p-1" onClick={() => this.navigate(rp)}>
                            {p}
                          </button>
                        </li>
                      );
                    })}
                  </ol>
                </nav>
              </div>
              <div className="ml-2 mt-2">
                <button
                  className={`btn btn-small btn-${mtimesort ? 'success' : 'info'}`}
                  onClick={() => this.setState({ mtimesort: !mtimesort })}
                >
                  <Octicon icon={Clock} size="small" />
                  <span className="ml-2">{mtimesort ? 'Sorted by mtime' : 'Sort by mtime'}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-12" />
        </div>
        <div className="row">
          <div className="col-12">
            <div className="list-group">
              {data.showUp && (
                <a
                  href={parentDir}
                  className="list-group-item list-group-item-action"
                  onClick={e => {
                    e.preventDefault();
                    this.navigate(parentDir);
                  }}
                >
                  <span className="mr-2">
                    <Octicon icon={FileDirectory} size="small" />
                  </span>
                  <span>..</span>
                </a>
              )}
              {data.dirs &&
                data.dirs.map((file, i) => {
                  return (
                    <a
                      href={`${path}/${file.name}`}
                      onClick={e => {
                        e.preventDefault();
                        const { path } = this.state;
                        this.navigate(`${path}/${file.name}`);
                      }}
                      key={i}
                      className="list-group-item list-group-item-action"
                    >
                      <span className="mr-2">
                        <Octicon icon={FileDirectory} size="small" />
                      </span>
                      <span>{file.name}</span>
                    </a>
                  );
                })}
              {data.files &&
                data.files.map((file, i) => {
                  const d = new Date(file.mtime);
                  return (
                    <a href={`${path}/${file.name}`} key={i} className="list-group-item list-group-item-action">
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <span className="mr-2">
                            <Octicon icon={File} size="small" />
                          </span>
                          <span>{file.name}</span>
                          <div>
                            <small>
                              Downloads: <strong>{file.downloads}</strong>
                            </small>
                          </div>
                        </div>
                        <div className="text-right">
                          <em>{getReadableFileSizeString(file.size)}</em>
                        </div>
                        <div className="ml-3">
                          <small>
                            {d.toLocaleDateString()} {d.toLocaleTimeString()}
                          </small>
                        </div>
                        <div className="ml-3">
                          <button
                            title="Copy to Clipboard"
                            className="btn btn-sm btn-link"
                            onClick={e => {
                              e.stopPropagation();
                              e.preventDefault();
                              copyToClipboard(
                                `${window.location.protocol}//${window.location.host}${path}/${file.name}`,
                                () => {
                                  ltToast('Link copied to clipboard');
                                }
                              );
                            }}
                          >
                            <Octicon icon={Clippy} size="small" />
                          </button>
                          {user.data.sl_writer && (
                            <button
                              title="Delete file"
                              className="btn btn-sm btn-outline-danger"
                              onClick={e => {
                                e.stopPropagation();
                                e.preventDefault();
                                this.onDeleteFile(`${path}/${file.name}`);
                              }}
                            >
                              <Octicon icon={Trashcan} size="small" />
                            </button>
                          )}
                        </div>
                      </div>
                    </a>
                  );
                })}
            </div>
            {user.data.sl_writer && data.showUp && data.files && data.files.length === 0 && (
              <div className="mt-3">
                <button
                  title="Delete Directory"
                  className="btn btn-sm btn-outline-danger"
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    this.onDeleteDirectory(path, parentDir);
                  }}
                >
                  <Octicon icon={Trashcan} size="small" />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    if (!this.props.user) {
      return;
    }
    const { match, location } = this.props;
    if (location.pathname === match.path) {
      this.navigate(rootPath);
    } else {
      this.loadDirectory(location.pathname.substring(match.path.length));
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { location, match } = this.props;
    if (prevState.mtimesort !== this.state.mtimesort) {
      localStorage.setItem('navsortmtime', this.state.mtimesort ? '1' : '0');
      this.loadDirectory(location.pathname.substring(match.path.length));
      return;
    }
    if (location.pathname !== prevProps.location.pathname) {
      this.loadDirectory(location.pathname.substring(match.path.length));
    }
  }

  onDeleteDirectory = (path, parentDir) => {
    const ahead = window.confirm('Are you sure to delete the directory?');
    if (ahead) {
      deleteData(`/api${path}`)
        .then(() => {
          this.navigate(parentDir);
        })
        .catch(e => {
          console.error(e.message);
        });
    }
  };

  onDeleteFile = path => {
    const ahead = window.confirm('Are you sure to delete the file?');
    if (ahead) {
      deleteData(`/api${path}`)
        .then(() => {
          const { match, location } = this.props;
          this.loadDirectory(location.pathname.substring(match.path.length));
        })
        .catch(e => {
          console.error(e.message);
        });
    }
  };
  navigate = path => {
    const { match, history } = this.props;
    history.push(`${match.path}${path}`);
  };

  loadDirectory = path => {
    const { mtimesort } = this.state;
    fetch(`/api${path}${mtimesort ? '?sort=mtime' : ''}`)
      .then(res => res.json())
      .then(json => {
        this.setState({ path, data: json });
      })
      .catch(e => {
        console.error('Failed to get files', e.message);
      });
  };
}

function mapStateToProps(state) {
  const { user } = state;

  return {
    user
  };
}

export default connect(mapStateToProps)(checkUser(Navigator));
