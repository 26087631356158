import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import DomainMain from './DomainMain';
import DomainView from './DomainView';

class DomainRoot extends PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired
  };

  render() {
    const { match } = this.props;
    return (
      <div className="entity-wrapper mt-3">
        <Switch>
          <Route exact path={match.path} component={DomainMain} />
          <Route exact path={`${match.path}/:id`} component={DomainView} />
        </Switch>
      </div>
    );
  }
}

export default DomainRoot;
