import React, { PureComponent } from 'react';
import ProductRelease from '../../lib/models/ProductRelease';
import { Link } from 'react-router-dom';

class ProductReleasesTable extends PureComponent {
  state = {
    releases: [],
    error: ''
  };

  render() {
    const { releases, error } = this.state;
    return (
      <>
        {!!error && <div className="alert alert-danger">{error}</div>}
        {releases.length > 0 && (
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Product Release</th>
                <th>Release date</th>
                <th>Notes</th>
              </tr>
            </thead>
            <tbody>
              {releases.map(release => {
                return (
                  <tr key={release.name}>
                    <td>
                      <Link to={`/product-releases/${release.name}`}>{release.name}</Link>
                    </td>
                    <td>{release.created_at && new Date(release.created_at).toLocaleString()}</td>
                    <td>{release.message}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </>
    );
  }

  componentDidMount() {
    ProductRelease.list()
      .then(releases => {
        this.setState({ releases });
      })
      .catch(e => {
        this.setState({ error: e.message });
      });
  }
}

export default ProductReleasesTable;
