import { RECEIVE_CONFIG, REQUEST_CONFIG, FAILED_CONFIG } from './settingsActions';

const initialState = {
  isFetching: false,
  data: undefined
};

export default function settingsReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_CONFIG:
      return Object.assign({}, state, { isFetching: true });

    case RECEIVE_CONFIG:
      return Object.assign({}, state, { isFetching: false, data: action.data });

    case FAILED_CONFIG:
      return Object.assign({}, state, { isFetching: false, data: false });

    default:
      return state;
  }
}
