import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import checkUser from './UserComponent';
import { setTitle } from '../lib/utils/windowutils';
import { Link } from 'react-router-dom';
import Octicon, { FileSymlinkDirectory } from '@githubprimer/octicons-react';

class MainComp extends PureComponent {
  static propTypes = {
    user: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    setTitle('Home');
  }

  render() {
    const { user } = this.props;
    return (
      <div className="mt-3">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-4">
            <h5>
              Welcome <strong>{user.data.name}</strong>
            </h5>
          </div>
        </div>
        {user.data.sl_reader && (
          <div className="row mt-5">
            <div className="col-12">
              <div>
                <Link to="/navigator" className="btn btn-info">
                  <Octicon icon={FileSymlinkDirectory} size="small" /> Browse packages
                </Link>

                <Link to="/product-releases" className="btn btn-info ml-3">
                  <Octicon icon={FileSymlinkDirectory} size="small" /> Browse product releases
                </Link>
              </div>
              <div className="mt-3">
                <p>
                  To download files from CLI, you need to provide a personal <em>authorization token</em> that you can
                  generate <Link to="/profile/tokens">here</Link>.
                </p>
                <div className="card p-3 my-3">
                  <h4>
                    Using <em>curl</em>
                  </h4>
                  <code>
                    curl -H "Authorization: Bearer $(token)"
                    https://releases.smartlis.com/releases/smartlis/core/frontend/smartlis_ui_ng/v2.0/smartlis_ui_ng-v2.0.0.tar.gz
                  </code>
                  <code>
                    curl
                    https://releases.smartlis.com/releases/smartlis/core/frontend/smartlis_ui_ng/v2.0/smartlis_ui_ng-v2.0.0.tar.gz?token=$(token)
                  </code>
                </div>
                <div className="card p-3 my-3">
                  <h4>
                    Using <em>wget</em>
                  </h4>
                  <code>
                    wget --header "Authorization: Bearer $(token)"
                    https://releases.smartlis.com/releases/smartlis/core/frontend/smartlis_ui_ng/v2.0/smartlis_ui_ng-v2.0.0.tar.gz
                  </code>
                  <code>
                    wget
                    https://releases.smartlis.com/releases/smartlis/core/frontend/smartlis_ui_ng/v2.0/smartlis_ui_ng-v2.0.0.tar.gz?token=$(token)
                  </code>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;

  return {
    user
  };
}

const Main = connect(mapStateToProps)(checkUser(MainComp));
export default Main;
