import { getData, postData, deleteData } from '../../utils/httputils';

class User {
  static apiPath = '/api/v1';
  static getMe() {
    return getData(User.apiPath + '/users/me');
  }

  static doLogout() {
    return deleteData(User.apiPath + '/users/login').then(() => {});
  }

  static doLogin(tokenId) {
    return postData(User.apiPath + '/users/login', { tokenId }).then(json => {
      return json.sessionId;
    });
  }
}

export default User;
