import React, { PureComponent } from 'react';
import { setTitle } from '../../lib/utils/windowutils';
import PropTypes from 'prop-types';
import { ltToast } from '../Common/LTToast';
import Octicon, { Plus, Trashcan, Eye, EyeClosed, Clippy } from '@githubprimer/octicons-react';
import Tokens from '../../lib/models/Tokens';
import { copyToClipboard } from '../../lib/utils/commonutils';

class TokenItem extends PureComponent {
  state = {
    masked: true
  };

  render() {
    const { masked } = this.state;
    const { token } = this.props;
    const created_at = new Date(token.created_at);
    return (
      <div className="list-group-item d-flex">
        <div className="flex-grow-1">
          <div>
            <span className="mr-1">
              <button
                className="btn btn-sm btn-link"
                title={masked ? 'Show token' : 'Hide token'}
                onClick={() => this.setState({ masked: !masked })}
              >
                <Octicon icon={masked ? Eye : EyeClosed} size="small" />
              </button>
            </span>
            <span className="mr-1">
              <button
                className="btn btn-sm btn-link"
                title={'Copy to token clipboard'}
                onClick={() =>
                  copyToClipboard(token.id, () => {
                    ltToast('Token copied to clipboard');
                  })
                }
              >
                <Octicon icon={Clippy} size="small" />
              </button>
            </span>
            <span className="mr-1">{masked ? token.idpartial : token.id}</span>
          </div>
          <div>
            <small>
              Added by {token.created_by === 'self' ? 'you' : token.created_by},{' '}
              {created_at.toLocaleDateString('it-IT')} {created_at.toLocaleTimeString('it-IT')}
            </small>
          </div>
        </div>
        <button
          className="btn btn-link"
          onClick={e => {
            this.props.deleteToken(e, token.id);
          }}
        >
          <Octicon icon={Trashcan} size="small" />
        </button>
      </div>
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.token.id !== this.props.token.id) {
      if (!this.state.masked) {
        this.setState({ masked: true });
      }
    }
  }
}

class Token extends PureComponent {
  static propTypes = {
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  state = {
    validated: false,
    tokens: [],
    searchString: ''
  };

  render() {
    const { tokens } = this.state;
    return (
      <div>
        <h5 className="mt-3">Tokens</h5>
        {tokens.length > 0 && (
          <div className="list-group mb-3">
            {tokens.map((token, i) => {
              return <TokenItem key={i} token={token} deleteToken={this.deleteToken} />;
            })}
          </div>
        )}
        {tokens.length === 0 && <div className="alert alert-info">No tokens found</div>}
        <p className="alert alert-warning">
          <small>
            Tokens are strictly personal and must not be shared. All downloads are recorded and linked to the token's
            owner.
          </small>
        </p>
        <div className="d-flex bd-highlight mb-3">
          <div className="mt-3" title="Create Token">
            <button disabled={tokens.length >= 3} onClick={this.createToken} className="btn btn-info">
              <Octicon icon={Plus} size="small" />
            </button>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    setTitle('Tokens');
    this.loadTokens();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.search !== this.props.location.search) {
      this.loadTokens();
    }
    const { domain, access_type } = this.state;
    let validated = false;
    if (domain && access_type) {
      validated = true;
    }
    this.setState({ validated });
  }

  loadTokens = () => {
    Tokens.find().then(tokens => {
      this.setState({ tokens });
    });
  };

  deleteToken = (e, code) => {
    const ok = window.confirm('Are you sure?');
    e.target.blur();
    if (ok) {
      setTimeout(() => {
        Tokens.delete(code)
          .then(() => {
            this.loadTokens();
          })
          .catch(e => {
            ltToast('Unable to delete domain: ' + e.message, 5000, true);
          }, 300);
      });
    }
  };

  createToken = () => {
    Tokens.create()
      .then(token => {
        this.loadTokens();
      })
      .catch(err => {
        ltToast('Unable to create token: ' + err.message, 5000, true);
      });
  };
}

export default Token;
