import { combineReducers } from 'redux';
import { userReducer } from './lib/models/user/';
import { settingsReducer } from './lib/models/settings/';
import { connectRouter } from 'connected-react-router';

const rootReducer = history =>
  combineReducers({
    user: userReducer,
    settings: settingsReducer,
    router: connectRouter(history)
  });

export default rootReducer;
