import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import checkUser from '../UserComponent';
import { Link } from 'react-router-dom';

class UserHome extends PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired
  };

  render() {
    const { match } = this.props;
    return (
      <div className="mt-3">
        <div className="row mb-3">
          <div className="col-md-6">
            <div className="card border-info">
              <div className="card-header bg-info text-white">
                <strong>Auth</strong>
              </div>
              <div className="card-body">
                <div className="list-group">
                  <Link to={`${match.url}/tokens`} className="list-group-item list-group-item-action">
                    Tokens
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default checkUser(UserHome);
