import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { setTitle } from '../../lib/utils/windowutils';
import { Route, Switch } from 'react-router-dom';
import AdminMain from './AdminMain';
import DomainRoot from '../Domain';
import AccountRoot from '../Accounts';
import checkUser from '../UserComponent';

class AdminComp extends PureComponent {
  static propTypes = {
    user: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    setTitle('Admin');
  }

  render() {
    const { match, user } = this.props;
    if (!user.data.sl_admin) {
      return <div>Unauthorized</div>;
    }
    return (
      <div className="container-fluid main-wrapper">
        <Switch>
          <Route exact path={match.path} component={AdminMain} />
          <Route path={`${match.path}/domains`} component={DomainRoot} />
          <Route path={`${match.path}/accounts`} component={AccountRoot} />
        </Switch>
      </div>
    );
  }
}

export default checkUser(AdminComp);
