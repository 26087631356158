import { deleteData, getData, postData } from '../utils/httputils';

class Tokens {
  static apiPath = '/api/v1/tokens';

  static create() {
    return postData(Tokens.apiPath);
  }

  static find() {
    return getData(Tokens.apiPath);
  }

  static get(id) {
    return getData(`${Tokens.apiPath}/${id}`);
  }

  static delete = id => {
    return deleteData(`${Tokens.apiPath}/${id}`);
  };
}

export default Tokens;
