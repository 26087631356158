import React, { PureComponent } from 'react';
import { setTitle } from '../../lib/utils/windowutils';
import Accounts from '../../lib/models/Accounts';
import PropTypes from 'prop-types';
import qs from 'qs';
import { ltToast } from '../Common/LTToast';
import Octicon, { CloudUpload, Dash, Plus, Search, Trashcan } from '@githubprimer/octicons-react';
import { handleOnKeyDown } from '../../lib/utils/commonutils';
import { Link } from 'react-router-dom';
import { getRolesAsString } from '../../lib/models/Roles';

class Account extends PureComponent {
  static propTypes = {
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  state = {
    validated: false,
    accounts: { rows: [] },
    email: '',
    name: '',
    searchString: '',
    adding: false
  };

  render() {
    const { match, history } = this.props;
    const { accounts, searchString, adding, validated, email, name } = this.state;
    const { offset } = this.getQueryParameters();
    return (
      <div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/admin/">Admin</Link>
            </li>
            <li className="breadcrumb-item active">Accounts</li>
          </ol>
        </nav>
        <div className="my-3 row">
          <div className="col-12 col-md-4 col-lg-3">
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" onClick={this.handleSearch}>
                  <Octicon icon={Search} size="small" />
                </span>
              </div>
              <input
                type="text"
                value={searchString}
                onChange={e => this.setState({ searchString: e.target.value })}
                onKeyDown={e => handleOnKeyDown(e, this.handleSearch)}
                className="form-control form-control-sm"
                placeholder="Search"
              />
              <div className="input-group-append">
                <button
                  disabled={searchString === ''}
                  className="btn btn-sm btn-outline-danger"
                  onClick={e => history.push(match.url)}
                >
                  <Octicon icon={Trashcan} size="small" />
                </button>
              </div>
            </div>
          </div>
        </div>
        {accounts.rows.length > 0 && (
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th>Email</th>
                <th>Roles</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {accounts.rows.map((account, i) => {
                return (
                  <tr key={i} className="justify-content-between align-items-center">
                    <td>
                      <Link to={`${match.path}/${account.id}`}>{account.email}</Link>
                    </td>
                    <td>{getRolesAsString(account.role)}</td>
                    <td>{account.status ? 'Enabled' : 'Disabled'}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        {accounts.rows.length === 0 && <div className="alert alert-info">No account found</div>}
        {adding && (
          <div className="row">
            <div className="col-12 col-md-6 col-lg-3">
              <form onSubmit={this.handleSubmit} className="mb-3 mt-3">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="accountInputCode"
                    name="email"
                    placeholder="Enter Email"
                    autoComplete="off"
                    value={email}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="accountInputCode"
                    name="name"
                    placeholder="Name"
                    autoComplete="off"
                    value={name}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="row mt-3">
                  <div className="col-12">
                    <button type="submit" className="btn btn-lg btn-info" disabled={!validated}>
                      <Octicon icon={CloudUpload} size="small" title="Save" />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
        <div className="d-flex bd-highlight mb-3">
          <div className="mt-3" title="Add Account">
            <button onClick={this.toggleAccount} className="btn btn-info">
              <Octicon icon={adding ? Dash : Plus} size="small" />
            </button>
          </div>
          {(Number(offset) || accounts.hasMore) && (
            <div className="mt-3 ml-auto p-2 bd-highlight">
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li className={'page-item' + (!Number(this.getQueryParameters().offset) ? ' disabled' : '')}>
                    <Link to={this.getPaginationUrl('p')} className="page-link" onClick={e => e.target.blur()}>
                      Previous
                    </Link>
                  </li>
                  <li className={'page-item' + (accounts.hasMore === false ? ' disabled' : '')}>
                    <Link to={this.getPaginationUrl('n')} className="page-link" onClick={e => e.target.blur()}>
                      Next
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          )}
        </div>
      </div>
    );
  }

  componentDidMount() {
    setTitle('Accounts');
    this.loadAccounts();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.search !== this.props.location.search) {
      this.loadAccounts();
    }
    const { email, name } = this.state;
    let validated = false;
    if (email && name) {
      validated = true;
    }
    this.setState({ validated });
  }

  loadAccounts = () => {
    const { search } = this.props.location;
    const parameters = qs.parse(search, { ignoreQueryPrefix: true });
    const { limit, offset, order_by, sort, q } = parameters;

    let searchString = '';
    if (q) {
      searchString = q;
    }
    if (this.state.searchString !== searchString) {
      this.setState({ searchString });
    }

    Accounts.find({ limit, offset, order_by, sort, search: q }).then(accounts => {
      this.setState({ accounts });
    });
  };

  handleSearch = () => {
    const { pathname, search } = this.props.location;
    const parameters = qs.parse(search, { ignoreQueryPrefix: true });
    parameters.q = this.state.searchString;
    parameters.offset = 0;
    this.props.history.push(pathname + qs.stringify(parameters, { addQueryPrefix: true }));
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { email, name, validated } = this.state;
    if (!validated) {
      return;
    }
    const payload = { email, name };
    Accounts.create(payload)
      .then(() => {
        this.loadAccounts();
      })
      .then(() => {
        this.toggleAccount();
      })
      .catch(err => {
        ltToast('Unable to create account: ' + err.message, 5000, true);
      });
    return false;
  };

  toggleAccount = () => {
    this.setState({ adding: !this.state.adding });
  };

  getQueryParameters = () => {
    const { search } = this.props.location;
    return qs.parse(search, { ignoreQueryPrefix: true });
  };

  getPaginationUrl = direction => {
    const { pathname } = this.props.location;
    const parameters = this.getQueryParameters();
    let offset = Number(parameters.offset) || 0;
    let limit = Number(parameters.limit) || 10;
    let offsetPrev = offset - limit;
    let offsetNext = offset + limit;
    if (offsetPrev < 0) {
      offsetPrev = 0;
    }
    parameters.offset = direction === 'p' ? offsetPrev : offsetNext;
    return pathname + qs.stringify(parameters, { addQueryPrefix: true });
  };
}

export default Account;
